import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Mood Board App Template | Mood Board UI Design | Uizard"
    canonical="/templates/mobile-app-templates/mood-board-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/mood-board-tablet-app/"
    metaDescription="Bring your mood board app design to life with our mood board app template. Uizard empowers you to design like never before with the power of AI."
    description="
    h2:Explore our mood board UI design template for tablet
    <br/>
    Looking to design your mood board app for image collection and sharing? Our brand-new mood board app design template is here to help you bring your vision to life. Simply sign up for Uizard Pro, open the template, and bring your project vision to life.
    <br/>
    h3:Collaborate with your team in real-time and bring your vision to life
    <br/>
    Uizard makes collaboration with your team or external stakeholders faster and easier than ever before. Invite teammates to view your project, gather feedback through comments and notes, and iterate rapidly. Creating a <a:https://uizard.io/mockups/>UI mockup</a> of your app idea has never been so easy.
    <br/>
    h3:Customize and iterate in a flash
    <br/>
    As with all our design templates, you can use this app template as it is. Looking to put your own spin on things? Our drag-and-drop editor is easy to use. Create screens or add new elements from our <a:https://uizard.io/templates/component-templates/>UI components library</a> library to shape the template into your own product vision.
    "
    pages={[
      "Sleek and stylish log in screen with user onboarding flow mockup",
      "Navigational landing page mockup with categories and highlights",
      "Category page mockups with relevant images feed",
      "Image landing page with image details and sharing features mockup",
    ]}
    projectCode="Z8mpy3EVmeUPMY4BgpMp"
    img1={data.image1.childImageSharp}
    img1alt="mood board tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="mood board tablet app design template profile screen"
    img3={data.image3.childImageSharp}
    img3alt="mood board tablet app design template homepage"
    img4={data.image4.childImageSharp}
    img4alt="mood board tablet app design template search screen"
    img5={data.image5.childImageSharp}
    img5alt="mood board tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/mood-board-tablet-app/mood-board-app-tablet-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/mood-board-tablet-app/mood-board-app-tablet-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/mood-board-tablet-app/mood-board-app-tablet-homepage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/mood-board-tablet-app/mood-board-app-tablet-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/mood-board-tablet-app/mood-board-app-tablet-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
